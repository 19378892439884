<template>
  <v-card flat rounded="lg" width="600">
    <v-container fluid class="ma-0 pa-0">
      <v-row dense>
        <v-col cols="12" sm="12" md="6" lg="6" class="pa-0">
          <v-img
            :src="mainImage"
            alt="Image"
            height="100%"
            class="rounded-l-lg"
          />
        </v-col>
        <v-col cols="12" sm="12" md="6" lg="6">
          <slot></slot>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
export default {
  data: () => ({
    mainImage: require("../../../assets/images/login.jpg")
  })
};
</script>
